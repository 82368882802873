

























































































.tabFoot {
    text-align: center;
    border-top: 0;
}

.noData {
    text-align: center;
    margin: 0.4rem 0;

    img {
        width: 5rem;
    }
}

.videoList {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-top: .3rem;

    .item {
        margin-bottom: .2rem;
        flex: 0 0 32%;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
 
        &:nth-child(3n) {
            margin-right: 0;
        }
        // &:nth-last-child(1),&:nth-last-child(2),&:nth-last-child(3){
        //     margin-bottom: 0;
        // }

        .imgBox {
            position: relative;
            height: 2.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            // border:1px solid #f2f4f6;
            overflow: hidden;
            .icon_play {
                $w: .5rem;
                $ww: 1rem;
                position: absolute;
                top: 50%;
                left: 50%;
                width: $w;
                margin-left: -$w/2;
                margin-top: -$w/2;
                @media (max-width:767px){
                    width: $ww;
                    margin-left: -$ww/2;
                    margin-top: -$ww/2;
                }
            }
            img{
                max-width: 100%;
                // max-height: 100%;
            }
        }

        .title {
            margin-top: .1rem;
            font-size: .24rem;
            padding:0 .2rem;
			margin-bottom: 0.3rem;
			overflow : hidden;
			/*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
            word-break: break-all;
			@media (max-width:767px){
			   font-size: 0.36rem;
			}
        }
        @media (max-width:767px){
            flex:0 0 100%;
            margin:0;
			    margin-bottom: 0.5rem;
			    border-bottom: 1px solid #cfcdcd;
        }

    }
}
